<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <button
    @mouseenter="openMenu"
    @mouseleave="closeMenu"
    class="ecvNavItem"
    :class="typeClass"
  >
    <div class="ecvNavItemTitleWapper">
      <div class="ecvNavItemTitle">
        <link-node tooltip :node="item"></link-node>
      </div>
    </div>
    <MenuBox :isOpen="openMenuBox" :secList="item.children" :type="type" />
  </button>
</template>

<script>
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
export default {
  props: {
    item: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },
  components: {
    MenuBox: () => import("./menuBox.vue"),
  },
  data: () => ({}),
  computed: {
    openMenuBox() {
      if (this.desktopMenuOpenIndex == -1) return false;
      return this.desktopMenuOpenIndex == this.index;
    },
    hasSecond() {
      return this.item.children && this.item.children.length > 0;
    },
    type() {
      if (!this.item.children || this.item.children.length == 0) return 1;
      if (this.checkHasThirdChild(this.item.children)) return 3;
      return 2;
    },
    typeClass() {
      if (this.type === 2) return "ecvMenuHasSec";
      if (this.type === 3) return "ecvMenuHasThird";
      return "";
    },
    desktopMenuOpenIndex() {
      return this.$store.getters["base/desktopMenuOpenIndex"];
    },
  },
  methods: {
    checkHasThirdChild() {
      const res = this.item.children.reduce((acc, cur) => {
        if (!cur) return acc;
        if (!cur.children) return acc;
        if (cur.children.length > 0) acc += 1;
        return acc;
      }, 0);
      return !!res;
    },
    async openMenu() {
      if (!this.hasSecond) return;
      this.$store.commit("base/setNav", { key: "desktopMenu", status: true });
      this.$store.commit("base/setDesktopMenuOpenIndex", this.index);
    },
    closeMenu() {
      this.$store.commit("base/setNav", { key: "desktopMenu", status: false });
      this.$store.commit("base/setDesktopMenuOpenIndex", -1);
    },
  },
};
</script>
